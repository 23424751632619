import React from 'react';
import twitter from '../../../assets/icons/twitter.svg';
import discord from '../../../assets/icons/discord.svg';
import instagram from '../../../assets/icons/instagram.svg';

function Footer() {
  return (
    <div className='flex flex-col justify-center pt-4 pb-5 bg-neu-200 dark:bg-neu-900 transition-all border-neu-500 border-t-[1px] select-none'>
      <div className='flex flex-row justify-center pt-2'>
        <a href='https://twitter.com/forgetyounotnft' target='_blank'>
          <img
            className='m-0.5 p-1 w-7 h-7 invert dark:invert-0 transition-all'
            alt=''
            src={twitter}
          />
        </a>
        <a href='https://discord.gg/7TEaJWS4VM' target='_blank'>
          <img
            className='m-0.5 p-1 w-7 h-7 invert dark:invert-0 transition-all'
            alt=''
            src={discord}
          />
        </a>
        <a href='https://instagram.com/forgetyounotnft' target='_blank'>
          <img
            className='m-0.5 p-1.5 w-7 h-7 invert dark:invert-0 transition-all'
            alt=''
            src={instagram}
          />
        </a>
      </div>
      <div className='pb-2 pt-1 text-center text-sm'>
        <span>©{new Date().getFullYear()} ForgetYouNot &times; </span>
        <span>
          Built by <a href='https://kismetlabs.xyz/'>Kismet Labs</a>
        </span>
      </div>
    </div>
  );
}

export default Footer;
