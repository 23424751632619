import React, { useContext, useEffect, useState } from 'react';
import TokenGallery from '../components/layout/gallery/TokenGallery';
import Web3Context from '../providers/Web3Context';

function Wallet() {
  const web3 = useContext(Web3Context);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    if (web3.account) {
      const getTokens = new Promise(async resolve => {
        const pairs = [];
        web3.getProjectsByUser(web3.account).then(async collectionsLocal => {
          for (const collectionId of collectionsLocal) {
            await web3
              .getTokensOfUserByProject(web3.account, collectionId)
              .then(tokenIds => {
                pairs.push({ collectionId, tokenIds });
              });
          }
          resolve(pairs);
        });
      });
      getTokens.then(r => {
        setCollections(r);
      });
    } else {
      setCollections([]);
    }
  }, [web3]);

  return (
    <main>
      {web3.account ? (
        <React.Fragment>
          <div className='text-3xl text-center mt-8 mb-4'>My Collections</div>
          {collections.length > 0 ? (
            collections.map(pair => (
              <TokenGallery
                collectionId={pair.collectionId}
                tokenIds={pair.tokenIds}
                key={pair.collectionId}
              />
            ))
          ) : (
            <div className='text-center text-xl m-6'>
              You have no collections.
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className='text-center text-3xl m-6'>{web3.message}</div>
      )}
    </main>
  );
}

export default Wallet;
