import React from 'react';
import { Link } from 'react-router-dom';

function GalleryItem(props) {
  return (
    <Link
      to={`/token/${props.token.collectionId}/${props.token.tokenId}`}
      className='group-inner h-full aspect-[16/10] flex-grow-0 flex-shrink-0 relative mr-4 last-of-type:mr-0 rounded-md overflow-hidden cursor-pointer'
    >
      {/* background image / video */}
      {props.token.isVideo ? (
        <video
          src={props.token.src}
          className='w-full h-full object-center object-cover'
        />
      ) : (
        <div
          className='absolute w-full h-full top-0 left-0 bg-cover bg-center bg-no-repeat'
          style={{ backgroundImage: `url(${props.token.src})` }}
        />
      )}
      {/* description */}
      <div className='opacity-0 group-inner-hover:opacity-100 transition-opacity absolute bottom-0 left-0 right-0 p-2 overflow-hidden bg-neu-900 bg-opacity-70 text-neu-50 select-none'>
        <div className='whitespace-nowrap'>{props.token.title}</div>
        {props.token.description && (
          <div className='whitespace-nowrap'>{props.token.description}</div>
        )}
      </div>
    </Link>
  );
}

export default GalleryItem;
