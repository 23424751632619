import React from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from './providers/ThemeContext';
import Navbar from './components/layout/nav/Navbar';
import Footer from './components/layout/nav/Footer';
import { Web3Provider } from './providers/Web3Context';
import { MintModalProvider } from './providers/MintModalContext';

function App() {
  return (
    <ThemeProvider>
      <Web3Provider>
        <MintModalProvider>
          <div className='bg-neu-100 dark:bg-neu-900 text-neu-900 dark:text-neu-50 flex flex-col min-h-[100vh] transition'>
            <Navbar />
            <div className='flex-grow mt-12'>
              <Outlet />
            </div>
            <Footer />
          </div>
        </MintModalProvider>
      </Web3Provider>
    </ThemeProvider>
  );
}

export default App;
