import React, { useContext, useEffect, useRef, useState } from 'react';
import GalleryItem from './GalleryItem';
import Web3Context from '../../../providers/Web3Context';
import PropTypes from 'prop-types';
import axios from 'axios';
import { MintModalContext } from '../../../providers/MintModalContext';
import rightChevron from '../../../assets/icons/chevron-right.svg';
import leftChevron from '../../../assets/icons/chevron-left.svg';
import CollectionButton from './CollectionButton';

function TokenGallery(props) {
  const web3 = useContext(Web3Context);
  const modal = useContext(MintModalContext);

  const [project, setProject] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [tokens, setTokens] = useState([]);
  const [scrollRatio, setScrollRatio] = useState(0);
  const scrollerRef = useRef();

  const fetchToken = async (tokenId, collectionId) => {
    return new Promise(async resolve => {
      await web3
        .getTokenURIByProject(collectionId, tokenId)
        .then(async metaLink => {
          await axios
            .get(metaLink)
            .then(async r => {
              const meta = r.data;
              const isVideo = (await getContentType(meta.image)).includes(
                'video'
              );
              const token = {
                key: tokenId,
                title: meta.name,
                description: undefined,
                src: meta.image,
                collectionId: collectionId,
                tokenId: tokenId,
                isVideo: isVideo
              };
              resolve(token);
            })
            .catch(err => {
              console.error(err);
            });
        });
    });
  };

  useEffect(() => {
    if (web3.account) {
      // get project details on-chain
      web3.getProject(props.collectionId).then(projectLocal => {
        setProject(projectLocal);
        // get token data for all tokens minted
        const addTokens = new Promise(async resolve => {
          const tokensLocal = [];
          // if tokenIds list is passed
          if (props.tokenIds && props.tokenIds.length > 0) {
            for (const t of props.tokenIds)
              tokensLocal.push(await fetchToken(t, props.collectionId));
            resolve(tokensLocal);
          }
          // fetch all tokens if no list passed
          else {
            for (let t = 1; t <= projectLocal.quantityMinted; t++)
              tokensLocal.push(await fetchToken(t, props.collectionId));
            resolve(tokensLocal);
          }
        });
        addTokens.then(tokensLocal => {
          setTokens(tokensLocal);
          setLoading(false);
        });
      });
    } else {
      setProject(undefined);
      setLoading(true);
    }
  }, [web3.account]);

  useEffect(() => {
    window.addEventListener('resize', setChevronValidity);
  }, []);

  useEffect(() => {
    setChevronValidity();
  });

  const getContentType = async url => {
    return new Promise(res => {
      axios.head(url + '?q=q').then(r => res(r.headers['content-type']));
    });
  };

  const setChevronValidity = () => {
    if (!scrollerRef.current) return;
    if (scrollerRef.current.scrollWidth <= scrollerRef.current.offsetWidth)
      setScrollRatio(undefined);
  };

  const scrollHandler = event => {
    const diff = event.target.scrollWidth - event.target.offsetWidth;
    if (diff <= 0) return setScrollRatio(undefined);
    setScrollRatio(event.target.scrollLeft / diff);
  };

  const adjustScroll = amount => {
    const step = amount / 50;
    let scrolledAmount = 0;
    const interval = setInterval(function () {
      try {
        scrollerRef.current.scrollLeft += step;
        scrolledAmount += step;
        if (Math.abs(scrolledAmount) >= Math.abs(amount))
          window.clearInterval(interval);
      } catch (e) {
        window.clearInterval(interval);
      }
    }, 1);
  };

  const getChevrons = () => {
    const threshold = 0.03;
    const adjustment = 450;
    if (scrollRatio === undefined) return null;
    const left =
      scrollRatio > threshold ? (
        <div
          onClick={() => {
            adjustScroll(-adjustment);
          }}
          className='select-none absolute h-48 top-4 left-0 rounded-r-md cursor-pointer flex flex-col justify-center bg-neu-100 dark:bg-black opacity-0 group-outer-hover:opacity-50 group-outer-hover:hover:opacity-100 transition-all z-[5]'
        >
          <img
            className='m-0.5 w-9 h-9 invert dark:invert-0 transition-all'
            alt=''
            src={leftChevron}
          />
        </div>
      ) : null;
    const right =
      scrollRatio < 1 - threshold ? (
        <div
          onClick={() => {
            adjustScroll(adjustment);
          }}
          className='select-none absolute h-48 top-4 right-0 rounded-l-md cursor-pointer flex flex-col justify-center bg-neu-100 dark:bg-black opacity-0 group-outer-hover:opacity-50 group-outer-hover:hover:opacity-100 transition-all z-[5]'
        >
          <img
            className='m-0.5 w-9 h-9 invert dark:invert-0 transition-all'
            alt=''
            src={rightChevron}
          />
        </div>
      ) : null;
    return (
      <React.Fragment>
        {left}
        {right}
      </React.Fragment>
    );
  };

  const getButtons = () => {
    const mintButton = (
      <CollectionButton
        clickHandler={() => {
          modal.loadModal(project);
        }}
        children={'Mint'}
        color='blue'
      />
    );
    const notAvailable = (
      <CollectionButton
        clickHandler={() => {}}
        children={'Mint not available'}
        color='neu'
      />
    );
    return (
      <React.Fragment>
        {project &&
        project.mintOpen &&
        parseInt(project.quantityMinted) < parseInt(project.maxMints)
          ? mintButton
          : notAvailable}
      </React.Fragment>
    );
  };

  const getLoadingHtml = () => {
    return (
      <div className='my-4 flex flex-col justify-center px-4'>
        <span>Loading...</span>
      </div>
    );
  };

  const getLoadedHtml = () => {
    if (!project) return getLoadingHtml();
    const hasTokens = (
      <div className='group-outer h-56 w-full flex flex-row overflow-x-auto relative'>
        {getChevrons()}
        <div
          ref={scrollerRef}
          onScroll={event => {
            scrollHandler(event);
          }}
          className='h-auto w-full flex flex-row overflow-x-auto p-4 relative'
        >
          {tokens.map(token => (
            <GalleryItem token={token} key={token.key} />
          ))}
        </div>
      </div>
    );
    const noTokens = (
      <div className='my-4 flex flex-col justify-center px-4'>
        <span>No tokens in this collection yet.</span>
      </div>
    );
    return project.quantityMinted === '0' ? noTokens : hasTokens;
  };

  return (
    <div className='mb-8 p-4' onFocus={setChevronValidity}>
      <div className='text-xl flex flex-row align-middle px-4 whitespace-nowrap max-w-full overflow-x-scroll'>
        <div className='flex flex-col justify-center leading-none'>
          {project ? project.name : 'Loading...'}
        </div>
        {getButtons()}
      </div>
      {loading ? getLoadingHtml() : getLoadedHtml()}
    </div>
  );
}

TokenGallery.propTypes = {
  collectionId: PropTypes.string,
  tokenIds: PropTypes.array
};

export default TokenGallery;
