import React from 'react';
import { Link } from 'react-router-dom';

function NavItem(props) {
  return (
    <Link
      to={props.link}
      className='w-auto flex flex-col justify-center cursor-pointer py-2 px-3 text-center hover:bg-neu-300 dark:hover:bg-neu-700 cursor-pointer flex-shrink'
    >
      {props.name}
    </Link>
  );
}

export default NavItem;
