import React from 'react';
import PropTypes from 'prop-types';

function SlideshowChild(props) {
  return (
    <div
      className='absolute top-0 left-0 h-full w-full transition-opacity duration-1000'
      style={{ zIndex: props.z }}
    >
      {/* background image */}
      <div
        className='absolute top-0 left-0 w-full h-full bg-image'
        style={{ backgroundImage: `url(${props.slide.image})` }}
      />

      {/* background darken */}
      <div className='absolute top-0 left-0 w-full h-full bg-black opacity-70' />

      {/* background darken gradient */}
      <div className='absolute bottom-0 left-0 w-full h-1/6 bg-gradient-to-b from-transparent to-neu-900 opacity-0 dark:opacity-100 scale-[1.01]' />

      {/* text */}
      <div className='flex flex-col justify-center text-center w-full p-6 text-neu-50 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2'>
        <div className='text-4xl font-bold mb-2'>{props.slide.title}</div>
        {props.slide.description && (
          <div className='text-xl italic'>{props.slide.description}</div>
        )}
      </div>
    </div>
  );
}

SlideshowChild.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string
};

export default SlideshowChild;
