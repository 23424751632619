import React, { useEffect, useRef } from 'react';
import SlideshowChild from './SlideshowChild';
import PropTypes from 'prop-types';

function Slideshow(props) {
  const slideRef = useRef();

  useEffect(() => {
    if (props.slides.length > 20)
      console.error('Too many slides in slideshow.');
    let index = 0;
    const children = slideRef.current.children;
    clearInterval(slideRef.interval);
    slideRef.interval = setInterval(() => {
      children[index].style.opacity = '0';
      if (++index === props.slides.length) index = 0;
      children[index].style.opacity = '100%';
    }, 4500);
  }, []);

  return (
    <div
      className='w-full h-[600px] overflow-hidden relative'
      ref={slideRef}
      onScroll={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {props.slides.map((slide, index) => (
        <SlideshowChild slide={slide} z={22 - index} key={slide.title} />
      ))}
    </div>
  );
}

Slideshow.propTypes = {
  slides: PropTypes.array
};

export default Slideshow;
