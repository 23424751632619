import React, { useContext, useEffect, useState } from 'react';
import Web3Context from '../providers/Web3Context';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import opensea from '../assets/icons/opensea.svg';
import rarible from '../assets/icons/rarible.svg';
import loading from '../assets/loading.gif';

function Token() {
  const web3 = useContext(Web3Context);
  const params = useParams();

  const [meta, setMeta] = useState(undefined);
  const [address, setAddress] = useState('');
  const [osLink, setOsLink] = useState('');
  const [raribleLink, setRaribleLink] = useState('');
  const [fetchFailed, setFetchFailed] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  // todo: add marketplace links to meta as it's the same state

  useEffect(() => {
    if (web3.account) {
      // get URI for token
      web3
        .getTokenURIByProject(params.collectionId, params.tokenId)
        .then(metaLink => {
          if (!metaLink) return setFetchFailed(true);
          axios
            .get(metaLink)
            .then(r => {
              setMeta(r.data);
            })
            .catch(err => {
              console.error(err);
            });
        });
      // make OS URL and get Owner of token
      web3
        .projectLevelIdToId(params.collectionId, params.tokenId)
        .then(globalId => {
          if (!globalId) return setFetchFailed(true);
          setOsLink(
            `https://opensea.io/assets/ethereum/${web3.contractAddress}/${globalId}`
          );
          setRaribleLink(
            `https://rarible.com/token/${web3.contractAddress}:${globalId}?tab=details`
          );
          web3.ownerOf(globalId).then(owner => {
            if (!owner) return setFetchFailed(true);
            setFetchFailed(false);
            setAddress(owner);
          });
        });
    } else {
      setMeta(undefined);
      setFetchFailed(true);
    }
  }, [web3.account]);

  const getContentType = async url => {
    return new Promise(res => {
      axios.head(url + '?q=q').then(r => res(r.headers['content-type']));
    });
  };

  useEffect(() => {
    if (meta)
      getContentType(meta.image).then(type => {
        if (type.includes('video')) setIsVideo(true);
      });
  }, [meta]);

  const failResponse = (
    <div className='text-center text-3xl m-6'>Loading...</div>
  );

  const successResponse = (
    <div className='flex flex-col lg:flex-row'>
      <div className='lg:w-7/12 p-10 flex flex-row justify-center'>
        {meta ? (
          isVideo ? (
            <video
              controls
              src={meta.image}
              className='w-full h-auto rounded-md overflow-hidden shadow-neu-800 dark:shadow-black shadow-lg'
              style={{ maxHeight: '60vh', width: 'auto' }}
            />
          ) : (
            <img
              src={meta.image}
              alt=''
              className='w-full h-auto rounded-md overflow-hidden shadow-neu-800 dark:shadow-black shadow-lg'
              style={{ maxHeight: '60vh', width: 'auto' }}
            />
          )
        ) : (
          <div className='h-full'>
            <div className='h-full flex flex-col justify-center align-middle my-auto'>
              <img src={loading} alt='' className='h-9 w-9' />
            </div>
          </div>
        )}
      </div>
      <div className='lg:w-5/12 py-10 px-10 lg:pl-[unset]'>
        <div className='text-3xl font-bold'>{meta ? meta.name : ''}</div>
        {address && (
          <div className='mb-4 italic'>
            Owned by{' '}
            <a
              href={`https://etherscan.io/address/${address}`}
              target='_blank'
              rel='noreferrer'
              className='text-blue-600'
            >
              {address
                ? `${address.substring(0, 5)}...${address.substring(
                    address.length - 3,
                    address.length
                  )}`
                : ''}
            </a>
          </div>
        )}
        <div className='mb-4'>{meta ? meta.description : ''}</div>
        <div className='flex flex-row justify-start'>
          <a
            href={osLink}
            target='_blank'
            rel='noreferrer'
            className='block bg-blue-600 rounded flex-shrink px-1.5 py-1 mr-3 leading-none flex flex-row align-middle select-none'
          >
            <img className='m-0.5 mr-2 w-5 h-5 inline' alt='' src={opensea} />
            <span className='my-auto text-neu-50 select-none'>
              View on OpenSea
            </span>
          </a>
          <a
            href={raribleLink}
            target='_blank'
            rel='noreferrer'
            className='block bg-yellow-300 rounded flex-shrink px-1.5 py-1 leading-none flex flex-row align-middle select-none'
          >
            <img className='m-0.5 mr-2 w-5 h-5 inline' alt='' src={rarible} />
            <span className='my-auto text-neu-900 select-none'>
              View on Rarible
            </span>
          </a>
        </div>
      </div>
    </div>
  );

  return fetchFailed ? failResponse : successResponse;
}

export default Token;
