import React from 'react';

function CollectionButton(props) {
  if (props.color === 'blue')
    return (
      <div
        onClick={props.clickHandler}
        className='text-base bg-blue-600 hover:bg-blue-700 text-neu-50 rounded-md py-1 px-2 cursor-pointer ml-4 select-none'
      >
        {props.children}
      </div>
    );
  if (props.color === 'orange')
    return (
      <div
        onClick={props.clickHandler}
        className='text-base bg-orange-700 hover:bg-orange-800 text-neu-50 rounded-md py-1 px-2 cursor-pointer ml-4 select-none'
      >
        {props.children}
      </div>
    );
  return (
    <div
      onClick={props.clickHandler}
      className='text-base bg-neu-700 text-neu-50 rounded-md py-1 px-2 cursor-default ml-4 select-none'
    >
      {props.children}
    </div>
  );
}

export default CollectionButton;
