import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { MintModalContext } from '../../providers/MintModalContext';

function MintModal(props) {
  const modal = useContext(MintModalContext);

  const [value, setValue] = useState(0);
  const [dirty, setDirty] = useState(false);

  const clearModal = () => {
    modal.clearModal();
    setDirty(false);
  };

  const getMax = () => {
    return Math.min(
      props.collection.maxMints - props.collection.quantityMinted,
      10
    );
  };

  const getErrors = () => {
    if (value > getMax())
      return (
        <div className='text-red-600 text-sm text-center mt-2'>
          Maximum of {getMax()}
        </div>
      );
    else if (dirty && (value < 1 || !value))
      return (
        <div className='text-red-600 text-sm text-center mt-2'>
          Minimum of one
        </div>
      );
    else return null;
  };

  const getMintButton = () => {
    if (getErrors())
      return (
        <div className='text-lg font-bold grow bg-neu-600 text-neu-50 rounded-md py-1 px-2 w-auto select-none'>
          Mint!
        </div>
      );
    return (
      <div
        onClick={() => {
          modal.mint(value);
        }}
        className='text-lg font-bold grow bg-blue-600 hover:bg-blue-700 text-neu-50 rounded-md py-1 px-2 cursor-pointer w-auto select-none'
      >
        Mint!
      </div>
    );
  };

  return (
    <div
      onClick={clearModal}
      className='fixed w-full h-full top-0 left-0 m-0 p-0 bg-black bg-opacity-70 z-[100]'
    >
      <div
        className='absolute top-6 right-6 text-5xl leading-none cursor-pointer text-neu-50'
        onClick={clearModal}
      >
        &times;
      </div>
      <div
        onClick={event => {
          event.stopPropagation();
        }}
        className='absolute flex flex-col px-8 py-2 min-w-[20rem] justify-between top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-neu-200 dark:bg-neu-900 text-center text-lg text-neu-900 dark:text-neu-50 rounded-xl'
      >
        {/* header */}
        <div className='m-4 grow-0 shrink-0 font-bold'>
          Mint {props.collection.name}
        </div>
        {/* divider */}
        <div className='w-11/12 h-0 border-neu-600 border-b-[1px] mx-auto mb-6' />
        {/* body */}
        <div className='grow shrink'>
          <div className='mb-6'>
            &#128200;{'  '}
            {props.collection.quantityMinted} of {props.collection.maxMints}{' '}
            minted!
          </div>
          <input
            type='number'
            className='rounded p-2 overflow-hidden w-full bg-neu-50 dark:bg-neu-700'
            placeholder='Mint Quantity'
            max={getMax()}
            min={1}
            onChange={event => {
              setDirty(true);
              setValue(parseInt(event.target.value));
            }}
          />
          {getErrors()}
        </div>
        {/* footer */}
        <div className='flex align-middle my-6 grow-0 shrink-0 flex-row justify-center'>
          {getMintButton()}
        </div>
      </div>
    </div>
  );
}

MintModal.propTypes = {
  collection: PropTypes.object
};

export default MintModal;
