import React, { useEffect, useState } from 'react';
import ToggleTheme from '../../util/ToggleTheme';
import ConnectWallet from '../../web3/ConnectWallet';
import NavItem from './NavItem';
import NavLogo from './NavLogo';
import dark from '../../../assets/icons/hamburger-dark.svg';

function Navbar() {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      {/* mobile */}
      <div className='flex md:hidden absolute h-auto w-full flex-col justify-center bg-neu-200 dark:bg-neu-900 select-none border-neu-500 border-b-[1px] transition z-30'>
        <div className='h-12 w-full flex flex-row md:hidden justify-center'>
          <NavLogo />
          <div className='grow shrink' />
          <ToggleTheme />
          <div
            className='flex flex-col justify-center hover:bg-neu-300 dark:hover:bg-neu-700 cursor-pointer'
            onClick={() => {
              setOpen(!open);
            }}
          >
            <img
              className='px-2 m-0 w-9 h-9 dark:invert transition-all'
              alt=''
              src={dark}
            />
          </div>
        </div>
        {open && (
          <div
            className='flex flex-col justify-center'
            onClick={() => {
              setOpen(false);
            }}
          >
            <NavItem name='Home' link='/' />
            <NavItem name='About' link='/about' />
            <NavItem name='Experiences' link='/experiences' />
            <NavItem name='My Tokens' link='/wallet' />
            <div className='w-auto py-2 px-3 text-center cursor-pointer flex-shrink'>
              <ConnectWallet />
            </div>
          </div>
        )}
      </div>

      {/* desktop */}
      <div className='hidden md:flex absolute h-12 w-full flex-row justify-between bg-neu-200 dark:bg-neu-900 select-none border-neu-500 border-b-[1px] transition z-10'>
        <NavLogo />
        <div className='flex flex-row justify-center flex-shrink'>
          <NavItem name='Home' link='/' />
          <NavItem name='About' link='/about' />
          <NavItem name='Experiences' link='/experiences' />
          <NavItem name='My Tokens' link='/wallet' />
          <ToggleTheme />
          <div className='w-auto py-2 px-3 text-center cursor-pointer flex-shrink'>
            <ConnectWallet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Navbar;
