import React from 'react';
import ReactDOMClient from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import Home from './pages/Home';
import Error from './pages/Error';
import Wallet from './pages/Wallet';
import Token from './pages/Token';
import About from './pages/About';
import Experiences from './pages/Experiences';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}>
          <Route path='/' exact element={<Home />} />
          <Route path='/about' exact element={<About />} />
          <Route path='/wallet' exact element={<Wallet />} />
          <Route path='/experiences' exact element={<Experiences />} />
          <Route path='/token' exact>
            <Route path=':collectionId'>
              <Route path=':tokenId' element={<Token />} />
            </Route>
          </Route>
          <Route path='*' element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
