import React from 'react';
import background from '../assets/collage-wave.jpeg';
import texture from '../assets/texture.jpeg';
import alpha from '../assets/astro-beta.jpg';
import beta from '../assets/beta-image.jpg';
import astro from '../assets/banner-astro.jpg';
import heaven from '../assets/eth-beta.jpeg';

function Experiences() {
  return (
    <div>
      <header
        className={`h-[400px] bg-image relative`}
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className='absolute top-0 left-0 w-full h-full bg-black opacity-50' />
        <div className='absolute bottom-0 left-0 w-full h-1/6 bg-gradient-to-b from-transparent to-neu-900 opacity-0 dark:opacity-100' />
        <div className='flex flex-col justify-center text-center w-full p-6 text-neu-50 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2'>
          <div className='text-4xl font-bold mb-2'>FYN Experiences</div>
        </div>
      </header>
      <div className='px-10 my-16'>
        <section className='mb-16 relative container mx-auto xl:mt-10'>
          <div
            className='absolute bg-image min-w-full min-h-full gradient-mask'
            style={{ backgroundImage: `url(${texture})` }}
          />
          <div className='flex flex-col md:flex-row px-4 sm:px-12 py-10 relative'>
            <div className='flex order-last md:order-first flex-col md:mr-8 justify-center text-center'>
              <div className='text-2xl mb-2'>Digital Experiences</div>
              <div className='xl:w-2/3 mx-auto'>
                FYN Experiences are tailored to a certain artistic theme, from
                coping strategies to ways of living. These overall Experiences
                are larger in scale and ideation. They are the centerpoints of
                our project and exemplify values we hold as a group. AstroSigns
                is a Digital Experience, for example, and conveys our belief in
                astrology and the power of the stars.
              </div>
            </div>
            <div className='flex flex-col md:w-1/3 mb-8 md:mb-0 grow-0 shrink-0 justify-center p-8 sm:p-10 md:p-0 xl:px-12 xl:py-0'>
              <img
                src={alpha}
                alt=''
                className='rounded w-full h-auto shadow-lg dark:shadow-neu-800 aspect-square object-cover'
              />
            </div>
          </div>
          <div className='flex flex-col md:flex-row px-4 sm:px-12 py-10 relative'>
            <div className='flex flex-col md:w-1/3 mb-8 md:mb-0 grow-0 shrink-0 justify-center p-8 sm:p-10 md:p-0 xl:px-12 xl:py-0'>
              <img
                src={beta}
                alt=''
                className='rounded w-full h-auto shadow-lg dark:shadow-neu-800 aspect-square object-cover'
              />
            </div>
            <div className='flex flex-col md:ml-8 justify-center text-center'>
              <div className='text-2xl mb-2'>Exclusive Collections</div>
              <div className='xl:w-2/3 mx-auto'>
                Our project contains smaller Collections within each Experience.
                These Collections will expand on the parent Experience’s theme,
                while also touching on their own artistic and introspective
                themes. These serve as the supporting work for both our larger
                Experiences, as well as our views and the values the team holds.
                Such examples are the Reflections, We Both Naked, and the
                Chinese New Year Exclusive Collections, under the AstroSigns
                Experience.
              </div>
            </div>
          </div>
          <div className='flex flex-col md:flex-row px-4 sm:px-12 py-10 relative'>
            <div className='flex order-last md:order-first flex-col md:mr-8 justify-center text-center'>
              <div className='text-2xl mb-2'>Astrosigns</div>
              <div className='xl:w-2/3 mx-auto'>
                AstroSigns is a digital Experience and conveys our belief in
                astrology and the power of the stars as a coping strategy for
                living and a useful way to make sense of situations and
                relationships. The AstroSigns Experience has a strong artistic
                theme including several different models, dances, poses, color
                schemes, designs and perspectives. The goal of this Experience
                is to shine a light on not only the beautiful skies and 12
                astrological signs, but also the amazing models, artists, etc.
                who put in countless hours of work.
              </div>
            </div>
            <div className='flex flex-col md:w-1/3 mb-8 md:mb-0 grow-0 shrink-0 justify-center p-8 sm:p-10 md:p-0 xl:px-12 xl:py-0'>
              <img
                src={astro}
                alt=''
                className='rounded w-full h-auto shadow-lg dark:shadow-neu-800 aspect-square object-cover'
              />
            </div>
          </div>
          <div className='flex flex-col md:flex-row px-4 sm:px-12 py-10 relative'>
            <div className='flex flex-col md:w-1/3 mb-8 md:mb-0 grow-0 shrink-0 justify-center p-8 sm:p-10 md:p-0 xl:px-12 xl:py-0'>
              <img
                src={heaven}
                alt=''
                className='rounded w-full h-auto shadow-lg dark:shadow-neu-800 aspect-square object-cover'
              />
            </div>
            <div className='flex flex-col md:ml-8 justify-center text-center'>
              <div className='text-2xl mb-2'>Heaventy2</div>
              <div className='xl:w-2/3 mx-auto'>
                Heaventy2 is a digital Experience tailored more towards the
                theology of life and how it can aid you on your journey. Heavens
                from all different religions are equally important and deserve
                their own place in the art scene. It is important to be
                respectful yet creative when exploring such topics. The
                Heaventy2 Experience starts with a 25 step journey through a
                black hole, in which at the end, one will land safely and have
                their choice of Heavens to join!
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Experiences;
