import React, { useContext } from 'react';
import { ThemeContext } from '../../providers/ThemeContext';
import moon from '../../assets/icons/moon.svg';

function ToggleTheme() {
  const { theme, setTheme } = useContext(ThemeContext);

  function toggle() {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  }

  return (
    <div
      className='w-auto h-full flex flex-col justify-center cursor-pointer py-2 px-1 text-center hover:bg-neu-300 dark:hover:bg-neu-700 cursor-pointer flex-shrink'
      onClick={toggle}
    >
      <img
        className='px-2 m-0 w-8 h-8 dark:invert transition-all'
        alt=''
        src={moon}
      />
    </div>
  );
}

export default ToggleTheme;
