import React from 'react';
import background from '../assets/about.jpg';
import texture from '../assets/texture.jpeg';

function About() {
  return (
    <div>
      <header
        className={`h-[400px] bg-image relative`}
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className='absolute top-0 left-0 w-full h-full bg-black opacity-50' />
        <div className='absolute bottom-0 left-0 w-full h-1/6 bg-gradient-to-b from-transparent to-neu-900 opacity-0 dark:opacity-100' />
        <div className='flex flex-col justify-center text-center w-full p-6 text-neu-50 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2'>
          <div className='text-4xl font-bold mb-2'>About Our Studio</div>
        </div>
      </header>
      <div className='px-10 my-16'>
        {/* studio about */}
        <section>
          <div className='text-xl font-bold mb-2 text-center'>WHO ARE WE?</div>
          <p className='mb-6'>
            Forget You Not is an art project aimed at delivering the most
            special and quality work in the space. We are a group of over 70
            individuals from different backgrounds: photographers, models,
            artists, designers, studio assistance, dancers, project visionaries,
            3D digital experts, animators, gaming specialists, and much more! We
            support and embrace the LGBTQ+ community and stand for certain
            values such as cultural diversity, and living free. Our goal is to
            provide collectors with multiple different ‘Experiences’ filled with
            fascinating visuals and stories. All art is unique in design and
            production, and each Experience offers exposure to multiple
            different artistic, cultural, and introspective themes. A
            centerpoint of our work is coping strategies such as astrology or
            religion. Although impartial to which one you choose, we believe
            it’s important for everyone to have a healthy sense of belonging and
            structure of support.
          </p>
          <div className='text-xl font-bold mb-2 text-center'>
            WHAT IS AN ‘EXPERIENCE?’
          </div>
          <p className='mb-6'>
            An ‘Experience’ is what the Forget You Not team calls their NFT
            collections. FYN art is not only quality and unique, but it truly
            exposes one to the raw feelings we’re trying to convey. Whether it’s
            the beautiful designs, eye-catching poses, or supporting stories,
            viewers of FYN Experiences will undoubtedly feel as though they
            experienced a part of our story. As we said, our work touches on
            many different themes. Some incorporate astrology, theology, living
            free in our bodies, embracing diversity and change, and the
            companionship of animals and pets. Within each Experience are
            subcollections that touch on smaller, individual themes. A few of
            our Experiences include AstroSigns, Heaventy2, WannaBe, and more!
          </p>
          <div className='text-xl font-bold mb-2 text-center'>
            FORGET YOU NOT
          </div>
          <p className='mb-6'>
            We are contributing a portion of our revenue to select non-profit
            organizations, the likes of which will of course be disclosed to the
            community. We’re also open to hearing what you’ll have to say in
            terms of suggestions for the amount and destination of our
            contributions. It is our hope that with a humanistic, open,
            art-loving mind, the NFT community feels as strongly about our art
            as we do!
          </p>
        </section>
      </div>
    </div>
  );
}

export default About;
