import React, { useContext } from 'react';
import TokenGallery from '../components/layout/gallery/TokenGallery';
import Web3Context from '../providers/Web3Context';
import Slideshow from '../components/layout/slideshow/Slideshow';
import astro from '../assets/banner-astro.jpg';
import astroBeta from '../assets/astro-beta.jpg';
import ethBeta from '../assets/eth-beta.jpeg';

function Home() {
  const web3 = useContext(Web3Context);

  const projectsFeatured = [1, 2];

  const slides = [
    {
      title: 'Forget You Not',
      description: 'Forget us, you will not',
      image: astro
    },
    {
      title: 'Astrosigns',
      description: 'Aim for the stars',
      image: astroBeta
    },
    {
      title: 'Heaventy2',
      description: 'Welcome to your heaven',
      image: ethBeta
    }
  ];

  return (
    <div>
      <header className={`h-[600px] relative overflow-hidden`}>
        <Slideshow slides={slides} />
      </header>
      <main>
        <section>
          {web3.account ? (
            <React.Fragment>
              <div className='text-3xl text-center mt-8 mb-4'>
                All Collections
              </div>
              {projectsFeatured.map(id => (
                <TokenGallery collectionId={id.toString()} key={id} />
              ))}
            </React.Fragment>
          ) : (
            <div className='text-center text-3xl my-20 px-4'>
              {web3.message}
            </div>
          )}
        </section>
      </main>
    </div>
  );
}

export default Home;
