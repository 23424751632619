import React, { useContext, useEffect, useState } from 'react';
import Web3Context from '../../providers/Web3Context';

function ConnectWallet() {
  const web3 = useContext(Web3Context);
  const [badNetwork, setBadNetwork] = useState(false);

  useEffect(() => {
    setBadNetwork(web3.message.includes('network'));
  }, [web3]);

  const connectButton = (
    <div
      className='bg-blue-600 hover:bg-blue-700 text-neu-50 rounded-md py-1 px-2 cursor-pointer'
      onClick={web3.connect}
    >
      Connect
    </div>
  );

  const badNetworkPill = (
    <div className='bg-neu-600 text-neu-50 rounded-md py-1 px-2 cursor-default'>
      Check Network
    </div>
  );

  const connectedPill = (
    <div className='bg-emerald-600 text-neu-50 rounded-md py-1 px-2 cursor-default'>
      {web3.account &&
        `${web3.account.substring(0, 5)}...${web3.account.substring(
          web3.account.length - 3,
          web3.account.length
        )}`}
    </div>
  );

  return web3.account ? connectedPill : connectButton;
}

export default ConnectWallet;
