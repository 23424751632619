import React, { createContext, useContext, useState } from 'react';
import MintModal from '../components/web3/MintModal';
import Web3Context from './Web3Context';

export const MintModalContext = createContext(undefined);

export const MintModalProvider = ({ children }) => {
  const web3 = useContext(Web3Context);

  const [showModal, setShowModal] = useState(false);
  const [collection, setCollection] = useState(undefined);

  const clearModal = () => {
    setShowModal(false);
    setCollection(undefined);
  };

  const loadModal = collection => {
    setCollection(collection);
    setShowModal(true);
  };

  const mint = amount => {
    web3.mint(collection.id, amount).then(() => {
      clearModal();
    });
  };

  return (
    <MintModalContext.Provider value={{ clearModal, loadModal, mint }}>
      {children}
      {showModal && <MintModal collection={collection} />}
    </MintModalContext.Provider>
  );
};
